import React, { useState } from 'react'
import { Outlet } from 'react-router-dom'
import Page from '../components/Page'
import { API_URL } from '../constants/url.constant'
import { getMethod, postMethod } from '../utils/api'
import { API_STATUS_CODE } from '../constants/content.constant'
import { Typography } from '@mui/material'

function CustomOrder() {
    const [formState, setFormState] = useState({
        codeno: '',
        recipe: ''
    })
    const [statusList,setStatusList] = useState([])
    const handleChange = (e) => {
        setFormState({...formState, [e.target.name]: e.target.value })
    }
    const handleSubmit = async () => {
        try{
            if(formState?.codeno&&formState.recipe){
            let response;
            response = await postMethod(
                API_URL.POST_COUSTOM_ORDER,
                formState,
                false,
                false
              );
              if(response.status===API_STATUS_CODE.SUCCESS){
                getMachineState();  // Initial call

                const intervalId = setInterval(() => {
                    getMachineState();
                }, 3000);
              }}else{
                alert("Invalid Machine Code or Recipe");
              }
        }catch(e){
            console.log(e);
    
        }
    }

    const getMachineState =async ()=>{
        try{
            let response;
            response = await getMethod(
                API_URL.GET_MACHINE_STATE + "/" + formState?.codeno ,
                false,
                false
              );
              setStatusList(prevStatusList => [...prevStatusList, response.data]);
            console.log(response.data);
        }catch{

        }
    }
  return (
    <>
    <Outlet />
    <Page title="Clover Carte | Custom-Order">
    <div
          style={{
            height: "100vh",
            width: "100%",
            backgroundColor: "rgba(0,0,0,0.5)",
            zIndex: 1000000,
            position: "fixed",
            top: 0,
            left: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div style={{overflowY:'scroll',height:'100vh',width:'100%'}} className="card p-2">
            <h4 className="text-secondary">Create Custom Order</h4>
            <div className="row p-1">
              <div className="col-md-4 my-3 ">
                <label className='mb-2 fw-bold' >Machine Code</label>
                <input
                  name="codeno"
                  value={formState?.codeno||''}
                  onChange={(e) => handleChange(e)}
                  type="number"
                  className="form-control"     
                  required={true}
                  autoFocus

                />
              </div>
              <div className="col-md-4 my-3">
                <label className='mb-2 fw-bold'>Order No;</label>
                <input
                  name="orderno"
                  value={formState?.orderno||''}
                  onChange={(e) => handleChange(e)}
                  rows={4}
                  type="text"
                  className="form-control"
                  required={true}
                />
              </div>
              <div className="col-md-4 my-3">
                <label className='mb-2 fw-bold'>Recipe Code</label>
                <input
                  name="recipe"
                  value={formState?.recipe||''}
                  onChange={(e) => handleChange(e)}
                  rows={4}
                  type="text"
                  className="form-control"
                  required={true}
                />
              </div>
            </div>
            <div className="d-flex w-100 justify-content-end">
              {/* <button onClick={()=>handleClose()} className="btn btn-warning m-1">Cancel</button> */}
              <button onClick={()=>handleSubmit()} className="btn btn-success m-1">Submit</button>
            </div>
            <div className='list-container'>
            <Typography variant='h5' >Machine Status</Typography>
                <ol>
                    {statusList?.map((status,index)=>(
                        <li className='fw-bold' key={index}> {status}</li>
                    ))}
                    
                </ol>
            </div>
         
          </div>
        </div>
        </Page>
 
    </>
  )
}

export default CustomOrder
